<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="请选择" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		
		<h2>“Whistle” 사용자 서비스 계약</h2>
		
		<p>"Whistle" 응용 소프트웨어 및 관련 서비스를 이용해 주셔서 감사합니다! <br>
		귀하에게 보다 나은 서비스 제공을 위하여 "휘슬" 이용약관(이하 "본 약관"이라 합니다)을 주의 깊게 읽어보시기 바랍니다. "Whistle" 소프트웨어 및 관련 서비스를 사용하기 전에 본 계약, 특히 책임의 면제 또는 제한에 관한 조항, 권리 라이센스 및 정보 이용 약관, 특정 개인의 활성화 및 사용에 관한 계약을 주의 깊게 읽고 완전히 이해하십시오. 서비스 이용약관, 관련법령 및 분쟁해결 조항 등 그 중 책임의 면제나 제한 조항 등 중요한 내용은 굵은 글씨로 표시되어 있으므로 주의 깊게 읽어 보시기 바랍니다. 귀하가 18세 미만인 경우에는 법적 보호자와 동행하여 본 약관을 주의 깊게 읽고 완전히 이해하신 후, "Whistle" 소프트웨어 및 관련 서비스를 이용하기 전에 법적 보호자의 동의를 얻으시기 바랍니다. <br>
		귀하가 본 계약의 모든 내용을 완전히 수락하지 않는 한, 귀하는 "Whistle" 소프트웨어를 다운로드, 설치, 등록, 로그인, 사용(이하 통칭하여 "사용")하거나 "Whistle" 서비스를 사용할 권리가 없습니다. 어떤 방식으로든 "휘파람"을 얻거나 소프트웨어가 제공하는 모든 서비스
		(본 계약에서는 "사용"으로 통칭함). 귀하가 "Whistle" 소프트웨어 및 관련 서비스를 사용하는 경우 귀하는 본 계약을 완전히 이해하고 본 계약의 당사자로서 이를 준수할 것을 약속한 것으로 간주됩니다. <br>
		본 계약 내용에 대한 질문, 의견 또는 제안 사항이 있는 경우 "Whistle" 클라이언트의 "피드백 및 도움말" 페이지에 로그인하여 당사에 문의하실 수 있습니다. </p>
		
		<h3>1. 적용 범위</h3>
		<p>1.1 본 계약은 귀하와 CLICK ADVERTISING & MEDIA PTE. 사이에 체결됩니다.
		LTD.(이하 "회사" 또는 "당사")는 "Whistle" 소프트웨어의 다운로드, 설치, 등록, 로그인, 사용 및 "Whistle" 소프트웨어가 제공하는 관련 서비스를 받기 위한 것입니다. 회사는 "Whistle" 소프트웨어 및 관련 요구에 따라 "Whistle" 소프트웨어를 계속 운영하도록 계열사, 지배 회사, 승계 회사 또는 회사가 인정한 제3자 회사를 일방적으로 주선하거나 지정할 권리가 있습니다. 서비스 또는 운영. 또한, 본 계약과 관련된 특정 서비스는 회사의 계열사, 지배 회사, 승계 회사 또는 회사가 인정한 제3자 회사에 의해 귀하에게 제공될 수 있습니다. 귀하는 관련 서비스 콘텐츠를 수락함으로써 관련 권리와 의무를 수락한 것으로 간주되며 본 계약에도 구속된다는 점을 이해하고 이에 동의합니다. <br>
		1.2 "사용자"란 자연인, 법인 및 기타 조직을 포함하여 "Whistle" 소프트웨어 및 관련 서비스를 직간접적으로 획득하고 사용하는 모든 사용자를 의미합니다. 본 계약에서는 "사용자" 또는 "귀하"라고 합니다.
		1.3 "휘파람"이란 "휘파람"이라고 표시된 클라이언트 애플리케이션과 회사가 법적으로 소유하고 운영하는 회사의 웹사이트를 의미합니다. 회사는 애플리케이션 및 웹사이트의 명칭을 일방적으로 변경할 수 있는 권리(명칭 변경, 추가 등을 포함하되 이에 국한되지 않음)를 가집니다.
		1.4 본 계약의 내용에는 회사가 출시했고 앞으로도 계속 출시할 수 있는 "Whistle" 소프트웨어 및 관련 서비스("Whistle" 개인정보 보호정책 포함)에 대한 관련 계약, 규칙 등이 포함됩니다. 앞서 언급한 콘텐츠가 공식적으로 공개되면 회사는 적절한 방식(웹사이트 공지, 시스템 공지 등)으로 해당 내용을 읽어 보시기 바랍니다. 앞서 언급한 관련 계약 및 규칙은 본 계약의 필수적인 부분을 구성하며 귀하도 이를 준수해야 합니다.
		</p>
		
		<h3>2. "Whistle" 소프트웨어 및 관련 서비스 사용</h3>
		<p>
		2.1 귀하가 "Whistle" 소프트웨어 및 관련 서비스를 사용하는 경우 "Whistle" 클라이언트 애플리케이션을 다운로드하거나 사전 설치 또는 회사가 승인한 제3자 다운로드를 통해 Whistle 관련 웹사이트에 액세스할 수 있습니다. 귀하가 회사 또는 회사가 승인한 제3자로부터 소프트웨어를 취득하지 않는 경우, 회사는 "Whistle" 소프트웨어의 비공식 버전이 정상적으로 사용된다는 점을 보장할 수 없으며, 귀하가 입은 손실에 대해 회사는 아무런 책임도 없습니다. 결과적으로 고통받습니다. <br>
		2.2 회사는 다양한 단말 장치에 대해 서로 다른 응용 소프트웨어 버전을 개발할 수 있으므로 귀하는 실제 장치 조건에 따라 적절한 버전을 구하여 다운로드하여 설치해야 합니다. <br>
		2.3 귀하는 필요에 따라 "Whistle" 소프트웨어 및 관련 서비스를 사용하거나 "Whistle" 버전을 업데이트할 수 있습니다. "Whistle" 소프트웨어 및 관련 서비스를 더 이상 사용할 필요가 없는 경우 직접 제거할 수 있습니다. <br>
		2.4 회사는 사용자 경험과 서비스를 더욱 향상시키기 위해 수시로 소프트웨어 업데이트 또는 변경 사항(소프트웨어 수정, 업그레이드, 기능 향상, 새로운 서비스 개발, 소프트웨어 교체 등을 포함하되 이에 국한되지 않음)을 제공합니다. 회사는 "Whistle" 소프트웨어 및 관련 서비스의 보안을 보장하고 사용자 서비스를 개선하기 위해 소프트웨어 및 관련 서비스가 업데이트되거나 서비스 내용의 일부가 업데이트된 후 가능한 경우 시스템을 포함하되 이에 국한되지 않는 방법을 사용합니다. 프롬프트, 공지사항, 사이트 메시지 등 사용자는 업데이트된 버전이나 서비스에 대한 동의 여부를 선택할 권리가 있음을 알려드리며, 동의하지 않을 경우 일부 기능이 제한되거나 더 이상 사용할 수 없습니다. <br>
		2.5 회사가 사전에 명시적으로 승인하지 않는 한, 귀하는 "Whistle" 소프트웨어 및 관련 서비스에 대해 각색, 복사, 배포, 수직 검색, 미러링 또는 거래를 포함하되 이에 국한되지 않는 어떠한 형태로든 무단 액세스 또는 사용을 수행할 수 없습니다. <br>
		2.6 귀하는 "Whistle" 소프트웨어 및 관련 서비스를 사용할 때 해당 소프트웨어 및 관련 서비스와 관련된 단말 장치(예: 컴퓨터, 휴대폰 등)를 직접 준비해야 함을 이해합니다. 일단 "Whistle"을 열면 귀하의 단말기에 소프트웨어를 설치하거나 Whistle 관련 웹사이트에 접속하는 경우, 귀하가 "Whistle" 소프트웨어 및 관련 서비스를 이용하는 것으로 간주됩니다. "Whistle"의 모든 기능을 완전히 구현하려면 해당 단말 장치를 인터넷에 연결해야 할 수 있으며, 필요한 비용(예: 교통비, 인터넷 요금 등)을 귀하가 부담해야 함을 이해합니다. <br>
		2.7 회사는 귀하에게 "Whistle" 소프트웨어 및 관련 서비스를 합법적으로 사용할 수 있는 개인적, 취소 가능, 양도 불가능, 비독점적, 비상업적 권리를 부여합니다. 본 계약에서 명시적으로 승인하지 않은 기타 모든 권리는 여전히 회사에 있습니다. 귀하는 이러한 권리를 행사할 때 회사로부터 추가 서면 허가를 받아야 합니다. 동시에 회사가 앞서 언급한 권리를 행사하지 못하는 것은 회사에 해당하지 않습니다. 그러한 권리의 포기. <br>
		2.8 귀하는 등록 없이 "Whistle" 소프트웨어 및 관련 서비스를 사용할 수 있습니다. 동시에, 귀하는 "Whistle" 소프트웨어 및 관련 서비스를 더 잘 사용하고 귀하의 계정 보안을 보호하기 위해 댓글 서비스 등과 같은 특정 기능 및/또는 특정 개별 서비스를 제공한다는 사실도 이해합니다. 관련법령의 규정에 의거 실명등록 및 로그인은 반드시 실명정보를 제공한 후에만 이용 가능합니다. <br>
		2.9 귀하가 "Whistle" 소프트웨어 및 웹사이트에서 귀하의 권리를 침해하는 내용을 발견한 경우, 귀하는 Whistle 침해신고 창구의 규정에 따라 즉시 회사에 통보하고 귀하에게 관련 권리가 있음을 입증하는 예비 증거를 제출하시기 바랍니다. 현지 법률 및 규정에 따라 적시에 처리하십시오. 귀하가 "휘파람"에 게시한 콘텐츠가 관련 권리자로부터 침해 신고를 받은 경우, 귀하는 이의제기 신청서 및 관련 권리 증명 자료를 고객에게 제출하여 회사에 불만을 제기할 수도 있으며, 회사는 이에 대한 처리를 진행합니다. 현지 법률 및 규정에 따라 적시에 귀하의 사건을 처리하십시오.
		</p>

		<h3>3. '계정'에 대하여</h3>
		
		<p>3.1 "휘슬"은 사용자에게 등록 채널을 제공합니다. 사용자는 자신의 계정으로 합법적인 문자 조합을 선택하고 보안 요구 사항을 충족하는 자신의 비밀번호를 설정할 권리가 있습니다. 이용자가 설정한 계좌번호와 비밀번호는 이용자가 등록된 이용자로 로그인하여 "Whistle" 소프트웨어 및 관련 서비스를 이용할 때 사용하는 자격증명입니다. </p>
		<p>3.2 <strong>계정 취소</strong><br>
		귀하가 "휘슬" 계정 서비스 이용을 해지하고자 하는 경우, 다음 조건에 해당하는 경우 "휘슬" 계정 해지 신청을 할 수 있습니다.<br>
		(1) 귀하는 자신의 계정을 취소하고 '호루라기' 절차를 따르는 것만 신청할 수 있습니다.<br>
		(2) 귀하는 귀하의 계정을 해지하기 전과 "휘파람" 서비스를 이용하는 동안 귀하의 행위에 대해 여전히 상응하는 책임을 져야 합니다.<br>
		(3) 해지 성공 후에는 계정 기록, 기능 등이 복원되거나 제공되지 않습니다. <br>
		"Whistle" 계정에서 로그아웃해야 하는 경우 Whistle 클라이언트를 열고 안내에 따라 로그아웃하세요. </p>
		<p>3.3 귀하는 귀하가 설정한 계정이 국가 법률, 규정 및 회사의 관련 규칙을 위반해서는 안 된다는 점을 이해하고 약속합니다. 귀하의 계정 이름, 아바타, 프로필 및 기타 등록 정보와 기타 개인 정보에는 불법적이거나 유해한 정보가 포함되어서는 안 됩니다. 타인의 허락을 받아 타인의 명의로 계정을 개설하는 행위(타인의 이름, 이름, 글꼴 크기, 아바타 등을 혼동을 야기할 정도로 사용하는 것을 포함하되 이에 국한되지 않음)는 허용되지 않으며, "휘파람" 계정(수시 등록, 일괄 등록을 ​​포함하되 이에 국한되지 않음) 계정 및 기타 행위를 악의적으로 등록하는 것은 허용되지 않습니다. 귀하는 계정을 등록하고 사용할 때 관련 법령을 준수해야 하며, 국익을 침해하거나, 다른 국민의 정당한 권익을 침해하거나, 사회도덕에 해를 끼치는 행위를 하여서는 안됩니다. 회사는 귀하가 제출한 등록 정보를 검토할 권리가 있습니다. </p>
		<p>3.4 귀하가 "Whistle"에 등록한 계정은 본인만이 사용할 수 있는 계정이며, 회사의 서면 동의 없이는 계정을 증여, 대여, 양도, 판매 기타 어떠한 형태로든 타인에게 이용하게 하여서는 안됩니다. . 회사는 이용자가 계정의 최초 등록자가 아님을 발견하거나 믿을 만한 합리적인 근거가 있는 경우, 계정의 보안을 보호하기 위해 즉시 등록된 계정에 대한 서비스 제공을 중단하거나 종료할 수 있습니다. , 또는 계정을 취소합니다. </p>
		<p>3.5 귀하는 귀하의 개인 계정과 비밀번호의 보안과 기밀성을 유지할 책임이 있으며, 귀하가 수행하는 데이터 수정을 포함하되 이에 국한되지 않고 등록된 계정 이름으로 수행하는 활동에 대해 전적인 법적 책임을 집니다. "휘파람", 음성 출판, 지불 및 기타 운영 행위. 귀하는 귀하의 계좌번호와 비밀번호의 비밀을 매우 중요하게 생각해야 하며, 어떠한 경우에도 귀하의 계좌번호와 비밀번호를 타인에게 공개해서는 안 됩니다. 다른 사람이 귀하의 계정을 무단으로 사용하고 있거나 기타 보안 위반이 발생한 것을 발견한 경우 즉시 회사에 알려야 합니다. </p>
		<p>3.6 귀하는 계정의 비밀번호를 분실하거나 잊어버린 경우 회사의 이의신청 채널을 따라 즉시 이의신청을 하고 계정이나 비밀번호의 회수를 요청할 수 있습니다. 귀하는 회사의 비밀번호 검색 메커니즘이 불만 사항 양식에 기재된 정보가 시스템 기록 정보와 일치하는지 확인하기만 하면 되지만 불만 사항이 해당 계정의 실제 승인된 사용자인지 여부는 확인할 수 없다는 점을 이해하고 인정합니다. 회사는 귀하에게 계좌번호와 비밀번호를 올바르게 관리할 것을 특별히 상기시켜 드립니다. 사용이 끝나면 안전하게 퇴장해야 합니다. 귀하의 계정은 부적절한 보관, 기타 불가항력적인 요인 등 귀하 본인의 사유로 인해 도난당하거나 비밀번호가 분실될 수 있으며, 이에 대한 모든 책임은 귀하에게 있습니다. </p>
		<p>3.7 계정 등록, 이용 및 관리 시, 계정 등록 시 기재한 신원 정보의 진위 여부를 확인해야 하며, 진실하고 정확하며 적법하고 유효한 관련 신원 증명 자료 및 필수 서류를 사용하여 등록 및 관리해야 합니다. 계정 정보(귀하의 이름, 이메일 주소, 연락처 번호, 연락처 주소 등을 포함하되 이에 국한되지 않음). 국가 법률 및 규정에 따라 본 소프트웨어 및 서비스의 일부 기능을 사용하려면 실제 신원 정보를 입력해야 합니다. 관련 법률 및 규정에 따라 실명 인증을 완료하고 업데이트에 주의하시기 바랍니다. 위에서 언급한 관련 정보를 적시에 제공합니다. 귀하가 제출한 자료 또는 귀하가 제공한 정보가 부정확하거나, 사실이 아니거나, 표준에 어긋나거나, 불법적이거나, 회사가 그것이 틀리거나 사실이 아니거나 불법이라고 의심할 만한 이유가 있는 경우, 회사는 귀하에게 관련 서비스 제공을 거부할 권리가 있습니다. "Whistle" 소프트웨어 및 관련 서비스를 이용할 수 없거나 이용 중에 일부 기능이 제한될 수 있습니다. </p>
		<p>3.8 귀하는 직접 "Whistle" 계정을 등록하는 것 외에도 회사 및/또는 그 계열사를 포함하되 이에 국한되지 않고 법적으로 소유한 다른 소프트웨어 사용자 계정을 사용하기 위해 인증을 사용하도록 선택할 수도 있습니다. 실명으로 등록된 제3자 소프트웨어 또는 플랫폼 사용자로서, 제3자 소프트웨어 또는 플랫폼에 의해 제한되거나 금지된 경우를 제외하고 "Whistle" 소프트웨어 및 관련 서비스를 이용하려면 계정을 등록하고 로그인하세요. 위의 기존 계정으로 로그인 시 해당 계정이 실명으로 등록되어 있는지 확인하고 본 약관의 관련 조항을 적용하는 데 동의해야 합니다. </p>
		<p>3.9 귀하는 "Whistle" 소프트웨어 및 관련 서비스에 로그인하고 사용하는 것 외에도 "Whistle" 계정을 사용하여 로그인하고 회사 및 계열사가 제공하는 기타 소프트웨어 및 서비스를 사용할 수 있음을 이해하고 이에 동의합니다. 또는 다른 파트너. 귀하가 "Whistle" 계정으로 로그인하여 전술한 서비스를 이용하는 경우, 귀하는 "이용약관" 및 다른 소프트웨어 및 서비스 제공업체의 기타 계약 조항도 준수하게 됩니다. </p>
		<p>3.10 귀하 콘텐츠의 노출 및 게시 효율성을 향상시키기 위해 귀하는 이 소프트웨어/웹사이트의 귀하 계정과 해당 계정에 의해 게시된 모든 콘텐츠가 회사 및/또는 계열사에 자동으로 게시되도록 회사에 승인하는 데 동의합니다. 귀하의 계정을 사용하여 운영되는 일련의 클라이언트 소프트웨어 및 웹사이트. 이 소프트웨어/웹 사이트의 콘텐츠 게시, 수정 및 삭제 작업은 위의 클라이언트 소프트웨어 및 웹 사이트 시리즈와 동기화됩니다. <br>귀하가 등록 또는 동기화된 계정을 통해 회사 및/또는 계열사가 운영하는 일련의 클라이언트 소프트웨어 제품 및 웹사이트(있는 경우)에 로그인하는 경우에는 "사용자 계약" 및 기타 계약을 준수해야 합니다. 소프트웨어 제품 및 웹사이트 자체, 약관 조항. </p>
		<p>3.11 타인의 악의적인 공격, 본인의 사유, 기타 불가항력적인 요인으로 인해 귀하의 계정이 도난당하거나 분실된 경우, 이에 대한 책임은 전적으로 귀하에게 있으며, 회사는 어떠한 책임도 지지 않습니다. </p>
		<p>3.12 귀하는 계정 자원을 최대한 활용하기 위해, 등록 후 최초 로그인에 실패하거나 2개월 이상 연속으로 유휴 계정에 로그인하지 않는 경우 회사가 다음과 같은 조치를 취한다는 점을 이해합니다. 언제든지 귀하의 계정을 철회할 수 있는 권리. 귀하의 계정이 탈퇴된 경우 귀하는 이전에 보유했던 계정을 통해 로그인 및 "Whistle" 소프트웨어 및 관련 서비스를 이용할 수 없으며 귀하의 계정에 저장된 개인 설정 및 사용 기록은 복원되지 않습니다. </p>
		<p>귀하의 계정을 철회하기 전에 회사는 적절한 방법으로 귀하에게 메시지를 표시할 것이며, 관련 메시지를 받은 후 일정 기간 내에 로그인하지 않고 계정을 사용하지 않는 경우 회사는 계정을 철회할 것입니다. </p>
		<p>계정이 탈퇴된 경우에는 새 계정을 등록하여 로그인하고 "Whistle" 소프트웨어 및 관련 서비스를 이용할 수 있습니다. 귀하의 새 계정 등록, 로그인 및 사용 행위에는 여전히 본 계약의 관련 약관이 적용됩니다. </p>
		
		<h3>4. 이용자 개인정보 보호</h3>
		
		<p>4.1 당사는 이용자 정보 보호를 매우 중요하게 생각하며, 이용자의 개인정보를 보호하는 것은 회사의 기본 원칙 중 하나입니다. 회사는 이용자의 개인정보(즉, 독립적으로 이용자를 식별할 수 있거나 다른 정보와 결합하여 식별할 수 있는 정보)를 이용자와 함께 보호하기 위해 최선을 다하고 있습니다. </p>
		<p>4.2
		귀하는 "Whistle" 소프트웨어 및 관련 서비스를 이용하는 과정에서 회사가 귀하에게 제공할 수 있도록 귀하의 개인정보(이름, 전화번호, 위치 정보, 기기 정보 등을 포함하되 이에 국한되지 않음)를 제공해야 할 수 있습니다. 더 나은 서비스와 그에 상응하는 기술 지원을 제공합니다. 귀하는 회사가 법령, 본 약관 및 "Whistle" 개인정보취급방침을 준수한다는 전제 하에 귀하의 개인정보를 수집, 이용(상업적 협력 포함), 저장, 공유할 수 있는 권리가 있음을 이해하고 이에 동의합니다. 관련 개인정보 열람, 수정, 삭제, 동의철회에 대한 귀하의 권리를 보호해 드립니다.
		</p>
		<p>4.3 귀하는 제출한 개인 식별 정보를 언제든지 찾아보고 수정할 수 있습니다. 귀하는 보안 및 식별상의 이유로(예: 계정 또는 비밀번호 검색 및 이의 제기 서비스 등) 다음을 수행하지 못할 수 있음을 이해하고 이에 동의합니다. 등록 시 제공한 정보 수정 초기 등록 정보 및 기타 확인 정보 </p>
		<p>4.4 회사는 "Whistle" 소프트웨어 및 관련 서비스에 부합하는 보안 기술 및 기타 보안 조치를 사용하고, 귀하의 개인정보를 보호하기 위해 완전한 관리 시스템을 구축하고, 귀하의 정보가 부적절하거나 무단으로 사용되지 않도록 접근, 사용 또는 공개. </p>
		<p>4.5 회사는 다음과 같은 경우를 제외하고는 귀하의 동의 없이 귀하의 개인정보를 회사 외부의 회사, 조직, 개인에게 공개하지 않습니다.</p>
		<p>
		(1) 사전에 명시적인 승인과 동의를 얻습니다.<br>
		(2) 귀하가 직접 다른 회사, 조직, 개인에게 공유, 양도, 공개하는 행위<br>
		(3) 국가 안보, 국방 안보, 공공 안전, 공중 보건 및 공익과 직접적으로 관련된<br>
		(4) 관련법령의 요건, 의무적인 행정 및 사법적 요건에 따라, 또는 범죄 수사, 기소, 재판 및 판결 집행 등과 직접적으로 관련된 요건에 따라 필요한 경우 공개 또는 제공합니다. 회사는 법률 및 규정 준수를 전제로 앞서 언급한 정보 공개 요청을 받은 경우 상대방에게 소환장, 조사서 등 해당 법적 문서를 발급하도록 요구합니다. 회사는 모든 요청이 법적 근거가 있는지 그리고 행정 및 사법 부서가 특정 조사 목적을 위해 얻을 수 있는 법적 권리가 있는 데이터로 제한되는지 확인하기 위해 모든 요청을 주의 깊게 검토할 것입니다.<br>
		(5) 법령이 허용하는 한도 내에서 다른 "휘슬" 이용자, 회사 및 제휴사의 정당한 권익을 유지하고, 회사의 생명, 재산 등을 통제하거나 안전하고 안정적인 검색, 사기 등의 불법행위 방지 및 처리, 신용위험 감소 등 권리 보호 제품 또는 서비스 운영(본 계약에서 정한 약속 위반, 영리 목적의 개인정보 공개 또는 제공 제외)<br>
		(6) 법률 뉴스 보도, 정부 정보 공개 및 기타 채널 등 법적으로 공개된 정보에 포함된 개인정보<br>
		(7) 법률, 법규에 규정된 기타 상황. <br>
		</p>
		<p>4.6 "Whistle" 소프트웨어 및 관련 서비스를 사용할 때 귀하의 다양한 자산 계좌, 은행 카드, 신용 카드, 제3자 지불 계좌 및 해당 비밀번호 및 기타 중요한 정보를 공개하지 않도록 주의하십시오. 그렇지 않으면 귀하에게 손실이 발생할 수 있습니다. 이로 인해 발생한 모든 책임은 귀하에게 있습니다. </p>
		<p>4.7 "Whistle" 소프트웨어 및 관련 서비스에는 제3자가 제공하는 정보 또는 기타 서비스(웹사이트 포함)가 포함되거나 연결될 수 있다는 점을 고려하세요. 귀하는 그러한 서비스를 운영하는 제3자가 귀하에게 개인 정보 제공을 요청할 수 있다는 것을 알고 이해합니다. 회사는 귀하가 이러한 제3자의 이용약관, 개인정보 보호정책 및 기타 관련 약관을 주의 깊게 읽고, 귀하의 개인정보를 적절하게 보호하며, 필요한 경우에만 해당 제3자에게 제공해야 함을 구체적으로 알려드립니다. 본 계약(및 "Whistle" 소프트웨어 및 관련 서비스와 관련된 기타 계약 및 규칙("Whistle" 개인정보 보호정책을 포함하되 이에 국한되지 않음))은 제3자가 제공하는 서비스에는 적용되지 않으며 당사는 어떠한 법적 책임도 지지 않습니다. 제공된 개인정보로 인해 발생할 수 있는 결과에 대해 책임을 묻겠습니다.<br>사용자 개인정보 보호에 대한 자세한 내용은 "Whistle" 개인정보취급방침을 참조하시기 바랍니다.</p>
				
		<h2>5. 사용자 행동강령</h2>
		
		<h3>5.1 사용자 행동 요구사항</h3>
		<p>본 제품 및 관련 서비스 사용에 대한 책임은 귀하에게 있습니다. 법률에 의해 허용되거나 회사의 사전 서면 허가가 없는 한, 귀하는 "Whistle" 소프트웨어 및 관련 서비스를 사용할 때 다음 행위를 해서는 안 됩니다.</p>
			
		<p>5.1.1 "Whistle" 소프트웨어의 정상적인 작동을 방해, 파괴, 수정 또는 달리 영향을 미치기 위해 회사가 승인하거나 허용하지 않은 플러그인, 플러그인, 시스템 또는 제3자 도구를 사용하고 관련 서비스. </p>
			
		<p>5.1.2 다음을 포함하되 이에 국한되지 않는 컴퓨터 네트워크 보안을 위협하는 행위를 수행하기 위해 "Whistle" 소프트웨어 및 관련 서비스를 사용하거나 표적으로 삼습니다.</p>
		<ul>
		<li>(1) 타인 네트워크에 대한 불법 침입, 타인 네트워크의 정상적인 기능 방해, 네트워크 데이터 도용 및 네트워크 보안을 위협하는 기타 활동</li>
		<li>(2) 네트워크 침입, 정상적인 네트워크 기능 및 보호 조치 방해, 네트워크 데이터 도용 등 네트워크 보안을 위협하는 활동에 참여하는 데 특별히 사용되는 프로그램 및 도구를 제공합니다.</li>
		<li>(3) 다른 사람이 네트워크 보안을 위협하는 활동에 참여하고 있음을 알고 이들에게 기술 지원, 광고 홍보, 지불 및 정산 등을 제공합니다.</li>
		<li>(4) 승인되지 않은 데이터를 사용하거나 승인되지 않은 서버/계정에 액세스하는 행위</li>
		<li>(5) 허가 없이 공용 컴퓨터 네트워크 또는 타인의 컴퓨터 시스템에 접속하여 저장된 정보를 삭제, 수정 또는 추가하는 행위</li>
		<li>(6) 허가 없이 "Whistle" 시스템이나 네트워크의 약점을 조사, 스캔, 테스트하려고 시도하거나 네트워크 보안을 훼손하는 기타 행위를 수행하려고 시도하는 행위</li>
		<li>(7) "Whistle" 시스템이나 웹사이트의 정상적인 운영을 방해하거나 방해하려고 시도하는 행위, 의도적으로 악성 프로그램이나 바이러스를 퍼뜨리는 행위, 정상적인 네트워크 정보 서비스를 방해하고 방해하는 기타 행위</li>
		<li>(8) TCP/IP 패킷 이름이나 이름의 일부를 위조합니다. </li>
		</ul>
			
		<p>5.1.3 "Whistle" 소프트웨어의 소스 코드를 알아내기 위한 역엔지니어링, 역어셈블, 컴파일 또는 기타 시도. </p>
			
		<p>5.1.4 계정의 빈번한 등록 및 일괄 등록을 ​​포함하되 이에 국한되지 않는 "휘파람" 계정의 악의적 등록. </p>
			
		<p>5.1.5 법령, 이 약관, 회사의 관련 규정을 위반하는 행위, 기타 타인의 정당한 권익을 침해하는 행위. </p>
			
		<p>어떠한 경우에도 귀하의 행위가 위 약관을 위반하거나 위반할 우려가 있다고 회사가 믿을 만한 근거가 있는 경우, 회사는 이를 독립적으로 판단하고 처리할 수 있으며, 언제든지 사용자에 대한 서비스를 종료할 권리를 갖습니다. 사전 통지 없이 서비스를 제공하고 책임을 묻습니다. </p>
			
		<h3>5.2 정보 내용 사양</h3>
		<p>5.2.1 이용자는 필요에 따라 실명인증을 완료한 후 등록된 계정 또는 “휘슬” 협력 플랫폼 계정으로 “휘슬”에 로그인하여 콘텐츠 게시, 댓글 게시 등을 할 수 있습니다. </p>
			
		<p>5.2.2 회사는 댓글이 문명적이고 합리적이며 우호적이며 수준 높은 의견 교환이 되도록 최선을 다하고 있습니다. 댓글 사업의 발전을 추진하는 동시에 해당 정보보호 관리 역량을 지속적으로 강화하고, 댓글 자율성을 제고하며, 사회적 책임을 효과적으로 이행하고, 국내법규를 준수하며, 국민의 정당한 권익을 존중하고, 사회질서와 좋은 풍습을 존중합니다. </p>
			
		<p>5.2.3 사용자가 제작, 댓글 작성, 게시 및 유포하는 콘텐츠("Whistle" 플랫폼에 업로드된 미공개 비공개 동영상 및 게시 후 72시간 표시 기간이 포함된 스냅샷을 포함하되 이에 국한되지 않음)는 의식적으로 준수해야 합니다. 법령, 사회주의 제도, 국익, 공민의 정당한 권익, 사회 공공 질서, 도덕 및 정보의 진실성 등에 따라 "7대 이익"을 요구하지 않는 경우 회사는 즉시 상응하는 조치를 취합니다. 사용자는 다음 정보를 게시할 수 없습니다:</p>
		<ul>
		<li>(1) 헌법이 정한 기본 원칙에 반대하는 행위</li>
		<li>(2) 국가 안보를 위협하고 국가 기밀을 유출하는 행위</li>
		<li>(3) 국가 권력을 전복시키고, 사회주의 체제를 전복시키며, 국가 분열을 선동하고, 민족 단결을 훼손하는 행위</li>
		<li>(4) 국가의 명예와 이익을 훼손하는 행위</li>
		<li>(5) 테러리즘과 극단주의를 조장하는 행위</li>
		<li>(6) 민족 ​​증오와 차별을 조장하고 민족 단결을 훼손하는 행위</li>
		<li>(7) 지역적 차별과 증오를 조장하는 행위</li>
		<li>(8) 국가 종교 정책을 훼손하고 이단 및 미신을 조장하는 행위</li>
		<li>(9) 사회 질서를 교란하고 사회 안정을 훼손하기 위한 소문과 허위 정보를 조작하고 퍼뜨리는 행위</li>
		<li>(10) 음란물, 음란물, 도박, 폭력, 살인, 테러를 유포하거나 유포하거나 범죄를 조장하는 행위</li>
		<li>(11) 네트워크 보안을 위협하거나 인터넷을 사용하여 국가 안보, 명예 및 이익을 위협하는 행위</li>
		<li>(12) 타인을 모욕하거나 명예를 훼손하고 타인의 정당한 권익을 침해하는 행위</li>
		<li>(13) 폭력으로 타인을 위협하고 위협하며 인신 수색을 수행하는 행위,</li>
		<li>(14) 타인의 개인 정보, 개인 정보 또는 데이터를 관련시키는 행위</li>
		<li>(15) 더러운 말을 퍼뜨리고 사회 질서와 선량한 풍속을 훼손하는 행위</li>
		<li>(16) 타인의 사생활, 명예, 초상권, 지적재산권 및 기타 적법한 권리와 이익을 침해하는 콘텐츠</li>
		<li>(17) 상업 광고 또는 유사한 상업 권유 정보, 과도한 마케팅 정보 및 스팸 정보를 유포하는 행위,</li>
		<li>(18) 본 웹사이트에서 일반적으로 사용되는 언어 이외의 언어로 작성된 댓글</li>
		<li>(19) 댓글이 달린 정보와는 아무런 관련이 없습니다.</li>
		<li>(20) 게시된 정보가 의미가 없거나 기술 검토를 회피하기 위해 의도적으로 문자 조합을 사용하는 경우</li>
		<li>(21) 미성년자의 정당한 권익을 침해하거나 미성년자의 신체적, 정신적 건강을 해치는 행위</li>
		<li>(22) 타인의 허락 없이 타인의 사진을 몰래 촬영하거나 녹화하여 타인의 법적 권리를 침해하는 행위</li>
		<li>(23) 다음 상황을 포함하되 이에 국한되지 않는 공포, 폭력, 유혈, 고위험을 포함하거나 연기자 또는 타인의 신체적, 정신적 건강을 위협하는 콘텐츠:</li>
		<ul>
		<li>1) 폭력적이거나 자해적인 콘텐츠</li>
		<li>2) 생명과 건강을 위협하거나 칼이나 기타 위험한 도구를 사용하여 자신 또는 타인의 개인 및/또는 재산권을 위협하는 콘텐츠</li>
		<li>3) 타인이 부상이나 사망을 초래할 수 있는 위험하거나 불법적인 활동에 참여하도록 조장하거나 유도하는 콘텐츠. </li>
		</ul>
		<li>(24) 기타 법령, 정책, 공공질서 및 미풍양속에 위반되거나, "휘파람"의 정상적인 운영을 방해하거나, 다른 이용자 또는 제3자의 정당한 권익을 침해하는 정보. </li>
		</ul>
				
		<h2>6. '휘파람' 데이터 사용 사양</h2>
		
		<p>6.1 회사의 서면 허가 없이 사용자는 스스로 또는 승인하여 제3자가 본 계약의 "Whistle" 소프트웨어 및 관련 서비스의 정보 콘텐츠에 대해 다음 작업을 수행하도록 허용하거나 보조할 수 없습니다. </p>
		<ul>
		<li>(1) 홍보, 독서량 증가, 페이지뷰 증가 등을 포함하되 이에 국한되지 않는 상업적 목적으로 "Whistle" 소프트웨어 및 관련 서비스의 정보 콘텐츠를 복사하고 읽고 사용합니다.</li>
		<li>(2) "Whistle" 소프트웨어 및 관련 서비스의 정보 콘텐츠를 승인 없이 편집, 구성 및 배열한 다음 이를 Whistle 소프트웨어 및 관련 서비스의 소스 페이지 이외의 채널에 표시합니다.</li>
		<li>(3) "Whistle" 소프트웨어의 정보나 콘텐츠를 트래픽 생성, 판독량, 안내, 전송 또는 전송하기 위해 특수 로고, 특수 코드 등을 포함하되 이에 국한되지 않는 모든 형태의 식별 방법을 사용합니다. 스스로 또는 제3자를 도와 관련 서비스를 제공하는 행위, 도용 및 기타 부작용</li>
		<li>(4) 기타 "Whistle" 소프트웨어 및 관련 서비스의 정보 콘텐츠를 불법적으로 취득하는 행위. </li>
		</ul>
			
		<p>6.2 회사의 서면 허가를 받아 사용자가 "Whistle" 소프트웨어 및 관련 서비스의 정보와 콘텐츠를 공유하고 전달하는 경우에도 다음 사양을 준수해야 합니다.</p>
		<ul>
		<li>(1) 크롤링, 계산, 획득 등을 통해 얻은 인기 검색어, 적중률, 분류, 검색량, 클릭률, 열람량 등 관련 데이터를 회사의 사전 서면 동의 없이는 어떠한 방식으로도 사용할 수 없습니다. . 제3자에게 홍보, 제공 또는 공개하는 행위</li>
		<li>(2) "Whistle" 소프트웨어 및 관련 서비스의 홈페이지 링크 입구를 포함하되 이에 국한되지 않고 "Whistle" 소프트웨어 및 관련 서비스의 소스 웹페이지에는 어떠한 형태의 변경도 허용되지 않으며, "Whistle" 소프트웨어 및 관련 서비스에 대한 변경이 이루어지면 서비스 소스 페이지의 표시가 차단, 삽입, 팝업 창 등 어떤 형태로든 방해되어서는 안 됩니다.</li>
		<li>(3) "Whistle" 소프트웨어 및 관련 서비스의 정보 콘텐츠가 "스파이더" 프로그램을 포함하되 이에 국한되지 않는 모든 형태를 통해 제3자가 불법적으로 획득하는 것을 방지하기 위해 안전하고 효과적이며 엄격한 조치가 취해져야 합니다. ; < /li>
		<li>(4) 관련 데이터 콘텐츠는 회사의 서면 허가 범위를 벗어난 목적으로 사용되거나 어떠한 형태의 판매 및 상업적 사용을 수행하거나 어떠한 방식으로든 공개, 제공 또는 제3자에게 사용하도록 허용할 수 없습니다. </ 리> 리>
		<li>(5) 이용자가 "Whistle" 소프트웨어 및 관련 서비스 정보 콘텐츠를 제3자에게 공유, 전달, 복사하는 행위 역시 커뮤니티 자율규제 협약 등 회사가 정한 기타 규범 및 기준을 준수해야 합니다. 기타 기능에 대한 "휘파람" 관련 프로토콜. </li>
		</ul>
			
		<h2>7. 위반 처리</h2>
				
		<p>7.1 귀하가 본 계약 또는 기타 서비스 약관을 위반한 경우 회사는 독립적으로 판단하고 사전 경고를 받을 권리가 있으며, 게시를 거부하고, 정보 전송을 즉시 중단하고, 게시물, 오디오, 비디오 및 기타 콘텐츠를 삭제할 권리가 있습니다. 단기간 발언금지, 계정의 일부 또는 전체 기능을 제한하거나 계정을 영구 폐쇄하는 등의 조치를 취합니다. 회사는 처리 결과를 공지하고, 실제 상황에 따라 이용 재개 여부를 결정할 권리가 있습니다. 법령 위반이 의심되거나 불법 범죄가 의심되는 경우 관련 기록을 보관하며, 법령에 따라 관계 주무 기관에 신고하고 관계 주무 기관의 조사에 협조합니다. 회사는 삭제된 콘텐츠를 반환하지 않을 권리를 보유합니다. </p>
		<p>7.2 귀하가 본 계약이나 기타 서비스 약관을 위반하여 제3자가 불만을 제기하거나 소송을 제기하는 경우 귀하는 모든 법적 책임을 져야 합니다. 귀하의 불법 또는 계약 위반으로 인해 회사 및 그 계열사, 지배 회사 및 승계 회사가 제3자에게 보상을 하거나 국가 기관에 의해 처벌되는 경우 귀하도 회사 및 그 계열사, 지배 회사 및 승계 회사에 대해 전액 보상해야 합니다. 회사는 총 손실의 결과로 발생한 모든 손실에 대해 보상을 받습니다. </p>
		<p>7.3 회사는 법인 및 공민의 지적재산권, 명예권, 명의권, 사생활 보호권 및 기타 정당한 권리와 이익을 존중하고 보호합니다. 귀하는 "휘파람" 소프트웨어 및 관련 서비스 이용 시 업로드된 텍스트, 비디오, 오디오, 링크 등이 제3자의 지적재산권, 명예권, 성명권, 사생활 보호권 및 기타 권리와 적법한 권리를 침해하지 않음을 보증하며, 이해. 그렇지 않은 경우 회사는 권리 보유자 또는 관련 당사자로부터 통지를 받은 후 침해가 의심되는 콘텐츠를 삭제할 권리가 있습니다. 귀하는 제3자가 제기한 모든 청구에 대해 모든 법적 책임을 져야 하며, 귀하의 침해로 인해 회사와 그 계열사, 지배 회사 및 승계 회사가 손실(경제적, 영업권 등의 손실을 포함하되 이에 국한되지 않음)을 입은 경우, 귀하는 다음과 같이 해야 합니다. 또한 회사와 계열사, 지배 회사 및 승계 회사가 입은 모든 손실에 대해 전액 보상합니다. </p>
		<h2>8. 서비스의 변경, 중단 및 종료</h2>
		<p>8.1 귀하는 회사가 제공하는 "Whistle" 소프트웨어 및 관련 서비스가 기존의 기술과 여건에 따라 제공되는 것임을 이해하고 이에 동의합니다. 회사는 귀하에게 서비스를 제공하고, 서비스의 연속성과 보안성을 보장하기 위해 최선을 다합니다. 귀하는 회사가 불가항력, 바이러스, 트로이 목마, 해커 공격, 시스템 불안정, 제3자 서비스 결함 및 기타 다양한 보안 문제로 인한 침입을 포함하되 이에 국한되지 않는 법적, 기술적 및 기타 위험을 언제든지 예측하고 예방할 수 없다는 점을 이해합니다. 서비스 중단, 데이터 손실 및 기타 손실과 위험. </p>
		<p>8.2 귀하는 서비스의 전반적인 운영 요구를 충족하기 위해 회사가 "Whistle" 소프트웨어 및 관련 서비스를 공지 및 공지한 후 수정, 중단, 정지 또는 종료할 수 있는 권리가 있음을 이해하고 이에 동의합니다. 사용자에게 어떠한 보상에 대해서도 책임을 지지 않습니다. </p>
		<h2>9. 광고</h2>
		<p>9.1 귀하가 "Whistle" 소프트웨어 및 관련 서비스를 사용할 때 귀하는 다음 사항을 완전히 이해하고 동의합니다. 이 서비스에는 회사가 개인 또는 기업을 위해 출시한 정보, 광고 또는 브랜드 홍보 서비스가 포함될 수 있으며 회사가 "Whistle" 소프트웨어 및 관련 서비스를 사용할 수 있는 권리 및/또는 제3자 공급업체 및 파트너는 소프트웨어 및 관련 서비스에 상업용 광고, 프로모션 또는 정보(상업적 또는 비상업적 정보 포함)를 표시합니다. </p>
		<p>9.2 귀하가 광고에 동의하지 않는 경우, 귀하는 광고 정보를 해제할 권리가 있으며, "휘파람" 푸시 알림 서비스의 경우 귀하는 직접 서비스를 해제하거나 "" 이용을 중단할 권리가 있습니다. Whistle' 소프트웨어 및 관련 서비스를 제공합니다. </p>
		<p>9.3 회사는 법령이 정하는 바에 따라 광고 및 판촉에 관한 의무를 이행하며, 해당 광고, 판촉정보의 진실성, 신뢰성 등에 대해서는 귀하가 판단하고 이에 대한 책임은 귀하에게 있습니다. 법령에서 명시적으로 규정한 경우를 제외하고, 해당 광고, 판촉 정보로 인해 발생한 구매나 거래, 또는 위 내용으로 인해 발생한 피해나 손실에 대한 책임은 전적으로 사용자에게 있으며, 회사는 책임을 지지 않습니다. </p>
			
		<h2>10. 지적재산권</h2>
		<p>10.1 회사가 "휘슬"에 제공하는 콘텐츠(소프트웨어, 기술, 프로그램, 웹페이지, 텍스트, 그림, 이미지, 오디오, 비디오, 차트, 레이아웃 디자인, 전자 문서 등을 포함하되 이에 국한되지 않음) " 소프트웨어 및 관련 서비스 지적재산권은 회사에 귀속됩니다. 회사가 본 서비스를 제공하는 소프트웨어에 대한 저작권, 특허권, 기타 지적재산권은 회사에 귀속됩니다. 회사의 허가 없이는 누구든지 허가 없이 이를 사용할 수 없습니다("휘파람" 소프트웨어 및 관련 서비스에 포함된 콘텐츠를 로봇, 스파이더 등을 통해 모니터링, 복사, 전파, 표시, 미러링, 업로드, 다운로드하는 것을 포함하되 이에 국한되지 않음). , 등 프로그램 또는 장치). </p>
		<p>10.2 귀하는 "Whistle" 소프트웨어 및 관련 서비스를 사용할 때 게시되고 업로드된 텍스트, 사진, 비디오, 오디오 등이 모두 귀하가 원본이거나 법적으로 승인된 것(하위 라이센스 포함)임을 이해하고 이에 동의합니다. 귀하가 "휘파람"을 통해 업로드 또는 게시하는 모든 콘텐츠에 대한 지적재산권은 귀하 또는 원저작권자에게 귀속됩니다. </p>
		<p>10.3 귀하는 귀하가 "휘파람"을 통해 게시하고 업로드하는 콘텐츠(비디오 및/또는 비디오에 포함된 텍스트, 사진, 비디오, 오디오, 음악 작품, 사운드, 대화 등을 포함하되 이에 국한되지 않음)를 알고, 이해하고 동의합니다. 오디오), 부여 회사와 그 계열사, 지배 회사 및 승계 회사는 전 세계적으로 무료이며 비독점적이며 재라이선스가 가능한(다양한 수준을 통해) 권한(복제권, 번역권, 조립권 및 정보를 포함하되 이에 국한되지 않음)을 갖습니다. 네트워크 전파 권한), 개작 권한, 파생 상품 생산 등)의 사용 범위에는 현재 또는 기타 웹 사이트, 애플리케이션, 제품 또는 단말 장치 등이 포함되지만 이에 국한되지는 않습니다. 귀하는 전술한 권리의 부여에는 회사의 콘텐츠와 관련된 판촉, 광고, 마케팅 및/또는 홍보에서 콘텐츠의 사용 및 기타 활용(전체 또는 일부)이 포함된다는 점을 인정하고 이에 동의합니다. 및/또는 회사의 브랜드) 권리 및 라이센스. 의심의 여지를 없애기 위해 귀하는 위의 권리 부여에 귀하의 개인 이미지, 초상, 이름, 상표, 서비스 표시, 브랜드, 이름, 로고 및 회사 표시(있는 경우)의 사용, 복제 및 표시가 포함된다는 데 동의합니다. 기타 브랜딩, 마케팅 또는 판촉 자산. </p>
		<p>10.4 귀하는 업로드 및 게시된 귀하의 지적 재산권을 침해하는 콘텐츠에 대해 귀하를 대신하여 권리를 방어하기 위해 회사가 자체 이름을 사용하거나 전문적인 제3자에게 위임할 수 있는 권한을 부여하고 이에 동의합니다. 권리 보호 양식에는 다음이 포함되지만 이에 포함되지는 않습니다. 제한 사항: 침해 행위 모니터링, 권리 보호 편지 발송, 소송 제기, 중재, 조정, 화해 등을 할 때 회사는 권리 보호 문제에 대해 결정을 내리고 이를 독립적으로 실행할 권리가 있습니다. </p>
		<p>10.5 회사는 "Whistle"의 개발 및 운영을 위한 기술지원을 제공하며, "Whistle" 소프트웨어 및 관련 서비스의 개발 및 운영 과정에서 생성된 모든 데이터 및 정보에 대한 모든 권리를 갖습니다. </p>
		<p>10.6 어떠한 경우에도 허가 없이 회사의 상표, 서비스 마크, 상호, 도메인 이름, 웹사이트 이름 또는 기타 고유한 브랜드 표시(이하 통칭하여 "Whistle"이라고 함)를 사용하지 마십시오. 로고"). 회사의 사전 서면 동의 없이 귀하는 등록상표, 도메인 이름 등록 등을 어떤 방식으로든 단독으로 또는 본 약관에서 앞서 언급한 로고와 결합하여 표시, 사용, 신청할 수 없으며, 타인에게 명시하거나 암시할 수도 없습니다. 표시, 사용 또는 기타 권리 이러한 기호를 처리할 권리. 상기 회사의 상표, 로고 등을 본 약관을 위반하여 사용하여 회사 또는 타인에게 손해를 끼친 경우, 모든 법적 책임은 귀하가 집니다. </p>
				
		<h2>11. 면책조항</h2>
		<p>11.1 귀하는 "Whistle" 소프트웨어 및 관련 서비스가 다양한 요인에 의해 영향을 받거나 방해를 받을 수 있다는 점을 이해하고 동의하며, 회사는 다음을 보장하지 않습니다(포함하되 이에 국한되지 않음).</p>
		<p>11.1.1 회사가 사용자의 요구 사항에 완전히 적합합니다.</p>
		<p>11.1.2 회사는 중단되지 않고 적시에 안전하며 신뢰할 수 있거나 오류가 없습니다. 회사를 통해 사용자가 얻은 모든 소프트웨어, 서비스 또는 기타 자료는 사용자의 기대를 충족합니다.</p>
		<p>11.1.3 소프트웨어의 모든 오류는 수정됩니다. </p>
		<p>11.2 이용자는 금전 차용이나 기타 재산 관련 정보가 의심되는 온라인 정보, 계정 비밀번호, 광고 또는 판촉 행위에 대해 주의 깊게 판단하고 스스로 판단해야 합니다. 앞서 언급한 이유로 인해 귀하는 이익 손실을 입을 수 있습니다. 비즈니스 평판, 데이터 또는 기타 정보 회사는 유형 또는 무형의 손실에 대해 직접, 간접, 부수적, 특별, 결과적 또는 징벌적 손해에 대해 책임을 지지 않습니다. </p>
		<p>11.3 사용자는 "휘파람" 소프트웨어 및 관련 서비스를 사용하는 과정에서 불가항력(불가항력이란 예측할 수 없고 극복할 수 없으며 피할 수 없는 객관적인 사건을 말합니다)과 같은 요인에 직면할 수 있음을 이해하고 이에 동의합니다. , 정부 조치, 자연 재해, 사이버 사유, 해커 공격, 전쟁 또는 기타 유사한 사건을 포함하되 이에 국한되지 않습니다. 불가항력이 발생한 경우, 회사는 가능한 한 조속히 복구하도록 노력하지만, 불가항력으로 인해 이용자에게 손실이 발생한 경우, 이용자는 회사가 책임을 지지 않는다는 점에 동의합니다. </p>
		<p>11.4 회사는 본 약관에 따라 불법 콘텐츠를 처리할 권리가 있으며, 이 권리는 회사의 의무나 약속을 구성하지 않으며, 회사는 불법 행위가 적시에 발견되거나 그에 따라 처리된다는 점을 보장할 수 없습니다. </p>
		<p>11.5 사용자는 회사가 본 계약에 따른 서비스와 관련하여 상업적 상품성, 특정 목적에의 적합성 등을 포함하되 이에 국한되지 않는 어떠한 종류의 명시적 또는 묵시적 보증이나 조건도 제공하지 않는다는 점을 분명히 이해하고 이에 동의합니다. 본 계약에 따른 소프트웨어 및 관련 서비스 사용에 따른 위험은 전적으로 귀하의 책임입니다. </p>
		<p>11.6 이용자는 이 약관이 국가법령의 준수, 공공질서 및 선량한 풍속의 유지, 타인의 정당한 권익을 보호하기 위한 것임을 분명히 이해하고 이에 동의합니다. 다만, 이는 회사의 판단을 보장하지 않으며, 이는 사법 및 행정 기관의 판단과 완전히 일치하며, 이에 따른 결과는 이용자가 책임을 지는 것으로 이해하고 이에 동의합니다. </p>
				
		<h2>12. 개별 서비스에 관한 특약</h2>
		<p>12.1 "휘파람" 소프트웨어 및 관련 서비스에는 회사가 다양한 법적 방법으로 얻은 정보 또는 정보 콘텐츠 링크가 포함되어 있으며, 회사 및 계열사가 합법적으로 운영하는 기타 개별 서비스도 포함됩니다. 이러한 서비스는 "휘파람"에서 별도의 섹션으로 존재할 수 있습니다. 회사는 수시로 이러한 특별 섹션의 설정 및 서비스를 추가, 축소 또는 변경할 권리를 보유합니다. </p>
		<p>12.2 귀하는 "Whistle" 소프트웨어에서 위의 개별 서비스 기능을 활성화하고 사용할 수 있습니다. 특정 개별 서비스는 귀하가 해당 서비스에 대해 특별히 작성된 계약이나 귀하와 서비스 제공자 간의 관계를 규율하는 기타 규칙을 수락하도록 요구할 수도 있습니다. 회사는 귀하가 검토할 수 있도록 이러한 계약 및 규칙을 눈에 띄는 방식으로 제공합니다. 귀하가 위 서비스를 사용하기 시작하면 개별 서비스에 관한 관련 계약 및 규칙이 적용되는 것으로 간주됩니다. </p>
		<p>12.3 귀하가 "Whistle" 소프트웨어에서 제3자가 제공하는 소프트웨어 및 관련 서비스를 사용할 수 있는 경우, "Whistle" 소프트웨어에서 본 계약 및 기타 관련 규칙을 준수하는 것 외에도 제3자의 규정도 준수해야 합니다. 계약 및 관련 규칙. 제3자 소프트웨어 및 관련 서비스로 인해 발생하는 모든 분쟁, 손실 또는 손해는 귀하와 제3자가 해결해야 합니다. </p>
				
		<h2>13. 미성년자 이용약관</h2>
		<p>13.1 귀하가 18세 미만의 미성년자인 경우, "Whistle" 소프트웨어 및 관련 서비스를 사용하기 전에 부모 또는 기타 보호자의 감독 및 지도 하에 본 계약을 읽고 동의해야 합니다. </p>
		<p>13.2 회사는 미성년자의 개인정보 보호를 매우 중요하게 생각하며, 미성년자가 개인정보를 입력하는 경우 개인보호에 대한 인식을 강화하고 주의 깊게 취급하시기 바라며, "휘파람" 소프트웨어 및 관련 서비스를 아래의 규정에 따라 올바르게 이용하시기 바랍니다. 보호자의 지도. </p>
		<p>13.3 미성년자 사용자는 귀하가 법률, 규정 및 본 계약의 내용을 위반할 경우 귀하와 귀하의 보호자가 법률 조항에 따라 그로 인해 발생하는 모든 결과를 부담해야 함을 이해합니다. </p>
		<p>13.4 미성년자 사용자를 위한 특별 팁</p>
		<p>13.4.1 "휘슬" 소프트웨어 및 관련 서비스를 사용하는 청소년은 보호자의 감독과 지도 하에 합리적인 범위 내에서 인터넷을 올바르게 사용하는 방법을 배우고, 가상 사이버 공간에 중독되지 않도록 하며, 좋은 인터넷 습관을 길러야 합니다. </p>
		<p>13.4.2 청소년 이용자는 '전국청소년인터넷문명협약'을 준수해야 합니다.</p>
		<ul>
		<li>(1) 온라인 학습을 잘하고 잘못된 정보 검색을 피하세요.</li>
		       <li>(2) 정직하고 친절하게 의사소통하고 다른 사람을 모욕하거나 속이지 마십시오. </li>
		       <li>(3) 자기 보호 의식을 높이고 네티즌과의 가벼운 데이트를 피하세요.</li>
		       <li>(4) 네트워크 보안을 유지하고 네트워크 질서를 방해하지 않습니다.</li>
		<li>(5) 신체적, 정신적 건강에 유익해야 하며 가상의 시간과 공간에 탐닉해서는 안 됩니다. </li>
		</ul>
		<p>13.4.3 회사는 미성년자의 개인정보 권리를 보다 안전하게 보호하기 위해 미성년자의 내용이 포함된 콘텐츠를 게시할 경우 주의를 당부하며, 게시된 후에는 미성년자의 내용을 표시하는 '휘파람'에 동의한 것으로 간주됩니다. '의 정보, 초상, 음향 등을 수집하고, '휘파람'이 본 약관에 따라 미성년자와 관련된 콘텐츠를 이용 및 처리할 수 있도록 허용합니다. </p>
				
		<h2>14. 기타</h2>
		<p>14.1 본 계약의 성립, 유효성, 이행, 해석 및 분쟁 해결은 중화인민공화국 본토 법률의 적용을 받습니다. 본 계약의 조항이 중화인민공화국 본토 법률과의 충돌로 인해 무효화되는 경우, 해당 조항은 본 계약의 원래 의미에 최대한 가깝게 재해석되며 본 계약의 다른 조항은 여전히 ​​유효합니다. 완전한 힘과 효과. </p>
		<p>14.2 본 계약의 체결 장소는 중화인민공화국 베이징시 하이뎬구이며, 귀하와 회사 사이에 분쟁이 있을 경우 양 당사자는 우호적인 협상을 통해 최선을 다해 해결하도록 노력해야 합니다. 협상이 실패할 경우 , 귀하는 분쟁이 베이징시 하이뎬구 인민법원의 관할권에 회부되어야 한다는 데 동의합니다. </p>
		<p>14.3 회사는 국가 정책, 기술 조건, 제품 기능 등의 변경에 따라 본 약관을 수정할 권리를 가지며, 개정된 약관을 공시합니다. 전술한 내용이 공식적으로 공개되면 회사는 귀하가 본 약관의 최신 버전을 확인할 수 있도록 적절한 방법(공식 홈페이지 공지, 시스템 공지 등)으로 업데이트된 내용을 알려드립니다. 개정된 내용은 본 계약의 필수적인 부분을 구성하며 귀하도 이를 준수해야 합니다. 개정된 약관에 이의가 있으신 경우, 즉시 "Whistle" 소프트웨어 및 관련 서비스에 대한 로그인 및 이용을 중단하시기 바랍니다. "Whistle" 소프트웨어 및 관련 서비스에 로그인하거나 계속해서 이용하시는 경우, 귀하가 이에 동의하는 것으로 간주됩니다. 수정된 계약으로. </p>
		<p>14.4 본 계약의 제목은 편의와 읽기만을 위한 것이며 본 계약 조항의 의미나 해석에 영향을 미치지 않습니다. </p>
		<p>14.5 귀하와 회사는 독립된 독립체입니다. 어떠한 경우에도 본 계약은 회사가 사용자에 대해 어떠한 형태로든 명시적이거나 묵시적인 보증이나 조건을 구성하지 않으며, 두 회사 사이에 대행, 파트너십, 합작 투자 또는 고용 관계가 존재하지 않습니다. 두 당사자. </p>
		<p>14.6 본 계약의 저작권은 회사에 있으며 회사는 법률이 허용하는 범위 내에서 모든 해석 및 수정 권한을 보유합니다. </p>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				options: [
					{
						value: '中文',
						label: '中文'
					}, {
						value: 'English',
						label: 'English'
					}, {
						value: '한국인',
						label: '한국인'
					}, {
						value: '日本語',
						label: '日本語'
					}
				],
				value: '',
				language: ''
			}
		},
		created() {
			// url获取参数
			this.language = this.$route.query.language;
			if (this.language == 'zh_CN') {
				this.$router.push({
					path: '/UserServiceAgreementZh'
				})
			}
			if (this.language == 'en_US') {
				this.$router.push({
					path: '/UserServiceAgreementEn'
				})
			}
		},
		methods: {
			clickChange(value) {
				this.value = value;
				if (value == '中文') {
					this.$router.push({
						path: '/UserServiceAgreementZh'
					})
				}
				if (value == 'English') {
					this.$router.push({
						path: '/UserServiceAgreementEn'
					})
				}
				if (value == '한국인') {
					this.$router.push({
						path: '/UserServiceAgreementKo'
					})
				}
				if (value == '日本語') {
					this.$router.push({
						path: '/UserServiceAgreementJa'
					})
				}
			},
		}
	}
</script>

<style>
	.container {
		padding: 0.1rem 0.3rem;		
	}
	
	.lang {
		text-align: right;
		margin-bottom: 0.3rem;
	}

	h1,
	h2 {
		color: #333;
		font-size: 0.5rem;
	}

	h3 {
		margin-top: 20px;
		font-size: 0.4rem;
	}

	p {
		margin-bottom: 15px;
		font-size: 0.3rem;
	}
	
	li {
		font-size: 0.3rem;
	}
</style>